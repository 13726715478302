body {
  /* scroll-behavior: smooth; */
  /* overflow-y: scroll; */
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  height: 100% !important;

  /* to avoid sticking to top and bottom of screen when scrolling*/
  /* overflow: hidden; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  height: 100% !important;

  /* overflow-y: scroll; */
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

/* .divider {
  border-left: 1px solid black;
} */

/* // to hide scroll bar from desktop browsers */
* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* @font-face {
  font-family: "Roboto-Bold";
  src: local("Roboto-Bold"),
    url(./assets/fonts/Roboto-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto-Regular"),
    url(./assets/fonts/Roboto-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Tajawal-Regular";
  src: local("Tajawal-Regular"),
    url(./assets/fonts/Tajawal-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Tajawal-Bold";
  src: local("Tajawal-Bold"),
    url(./assets/fonts/Tajawal-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "YouthTouch-Regular";
  src: local("YouthTouch-Regular"),
    url(./assets/fonts/YouthTouch-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Lobster-Regular";
  src: local("Lobster-Regular"),
    url(./assets/fonts/Lobster-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "NotoSansJP-Light";
  src: local("NotoSansJP-Light"),
    url(./assets/fonts/NotoSansJP-Light.otf) format("truetype");
}

@font-face {
  font-family: "NotoSansJP-Regular";
  src: local("NotoSansJP-Regular"),
    url(./assets/fonts/NotoSansJP-Regular.otf) format("truetype");
} */
